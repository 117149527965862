import React, {useEffect, useState} from 'react';
import {
    BooleanInput,
    Create,
    Edit,
    FileInput,
    ImageField,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useGetList,
    usePermissions,
    WithRecord
} from 'react-admin';
import {useTranslation} from "react-i18next";
import hasPermission from "../auth/hasPermission";
import {useUmbrellaChoices} from "../lib/customHooks";
import Grid from "@mui/material/Grid";
import {Avatar, Typography} from "@mui/material";

const CharityFormFields = () => {
    const {t} = useTranslation();

    const {permissions} = usePermissions();

    // A charity (project) belongs to a specific charity, retrieve the options here
    const umbrellaChoices = useUmbrellaChoices();

    const [locationChoices, setLocationChoices] = useState([]);

    const {data: locations} = useGetList(
        'locations',
        {
            sort: {field: 'title', order: 'ASC'},
        },
    );

    useEffect(() => {
        if (locations && hasPermission(permissions, ["create", "edit"], "charity.location")) {
            let myLocationChoices = [];
            for (let i = 0; i < locations.length; i++) {
                let location = locations[i];
                let jsonOjbect = {name: `${location.title}`, id: location.id}
                myLocationChoices.push(jsonOjbect);
            }
            setLocationChoices(myLocationChoices);
        }
    }, [locations, permissions]);

    return (
        <div className="showie">
            <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                <Grid item xs={12}>
                    <div className="custom-box borderGreen">
                        <WithRecord
                            render={record =>
                                <div className="rowit">
                                    <Avatar sx={{height: 100, width: 100, marginRight: '16px'}}
                                            src={`${process.env.REACT_APP_CDN_URL}/${record.logo_name}.${record.ext}`}/>
                                    <div>
                                        <Typography sx={{fontWeight: 700, fontSize: '24px'}}>
                                            {record.charity_name}
                                        </Typography>
                                        {record.project_number && record.project_number !== '' && record.project_number !== null &&
                                            <Typography sx={{
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                fontStyle: 'italic',
                                            }}>({t('Project number')} : {record.project_number})</Typography>
                                        }
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
            <SimpleForm redirect="show">
                <Grid container spacing={4} sx={{padding: '8px'}} xs={12} lg={12}>
                    <Grid item xs={12} lg={6}>
                        <div class="custom-box">
                            <Typography variant="h6" gutterBottom>{t('General info')}</Typography>
                            <Grid container spacing={2} sx={{marginTop: '6px'}}>
                                {hasPermission(permissions, ["view"], "umbrella") &&
                                    <Grid item xs={12}>
                                        <SelectInput label={t('Umbrella')} source="parent_charity_id"
                                                     choices={umbrellaChoices} validate={required()}/>
                                    </Grid>
                                }
                                {locationChoices && locationChoices.length > 0 && hasPermission(permissions, ["create", "edit"], "charity.location") &&
                                    <Grid item xs={12}>
                                        <SelectInput label={t('Location')} source="location_id"
                                                     choices={locationChoices}
                                                     validate={required()}/>
                                    </Grid>
                                }
                                <Grid item xs={4}>
                                    <TextInput label={t('Short name')} source="short_name" validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput label={t('Name')} source="charity_name" validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <BooleanInput source="active" label={t('Is active')}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label={t('Sponsorship amount')} source="sponsorship_amount"
                                                 validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput source="website_link" label={t('Website link')}/>
                                </Grid>
                                {hasPermission(permissions, ["view"], "umbrella") &&
                                    <Grid item xs={4}>
                                        <BooleanInput source="demo_charity" label='Demo'/>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                        <div class="custom-box" style={{marginTop: '24px'}}>
                            <Typography variant="h6" gutterBottom>{t('Mail settings')}</Typography>
                            <Grid container spacing={2} sx={{marginTop: '6px'}}>
                                <Grid item xs={6}>
                                    <TextInput source="email" label={t('email')} validate={required()}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <BooleanInput source="send_new_post_mail" label={t('Send new post mail?')}/>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="custom-box" style={{marginTop: '24px'}}>
                            <Typography variant="h6" gutterBottom>{t('Address')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextInput source="city" label={t('City')} validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput source="country" label={t('Country')} validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label={t('Nr of countries')} source="nr_of_countries"
                                                 validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label={t('Nr of projects')} source="nr_of_projects"
                                                 validate={required()}/>
                                </Grid>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput source="lat" label="Lat"/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput source="lng" label="Long"/>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div class="custom-box">
                            <Typography variant="h6" gutterBottom>{t('Project info')}</Typography>
                            <Grid item xs={12}>
                                <FileInput labelSingle={`${t('Click to add or update logo')} (< 500Kb)`} source="logo"
                                           label={t('Logo')} maxSize={500000} accept="image/*">
                                    <ImageField source="src" title={t('Logo')}/>
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput multiline fullWidth source="description" label={t('Description')}
                                           validate={required()}/>
                            </Grid>
                        </div>
                        <div className="custom-box" style={{marginTop: '24px'}}>
                            <Typography variant="h6" gutterBottom>{t('Alert info')}</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <NumberInput label={t('Days orange alert')} source="days_orange_alert"
                                                 style={{width: 500}}
                                                 validate={required()}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInput label={t('Days red alert')} source="days_red_alert"
                                                 style={{width: 500}}
                                                 validate={required()}/>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

            </SimpleForm>
        </div>
    );
}

export const CharityEdit = (props) => {
    const {t} = useTranslation();

    return (
        <Edit {...props} title={t('Edit charity')} redirect="show">
            <CharityFormFields/>
        </Edit>
    );
}

export const CharityCreate = (props) => {
    const {t} = useTranslation();

    return (
        <Create {...props} title={t('Create new charity')} redirect="show">
            <CharityFormFields/>
        </Create>
    );
}
